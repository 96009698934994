import {nodeInstance, phpInstance} from "@/plugins/axios";
import store from "@/store";

const eventsService = {};

eventsService.newEvent = obj => {
  return nodeInstance.post('/api/event', obj);
}

eventsService.validateEventCode = (id, code) => {
  return nodeInstance.get(`/api/event/code/validation`, {params: { id, code } });
}

eventsService.editEvent = (id, obj) => {
  return nodeInstance.put(`/api/event/${id}`, obj);
}

eventsService.deleteEvent = (id) => {
  return nodeInstance.delete(`/api/event/${id}`);
}

eventsService.getMyCreatedEvents = () => {
  return nodeInstance.get('/api/event');
}

eventsService.getLastEvents = () => {
  return nodeInstance.get('/api/event/last', {params:{take:8}});
}

eventsService.getTodaysEvents = () => {
  return nodeInstance.get('/api/event/current');
}

eventsService.registerForAnEvent = (eventId) => {
  return nodeInstance.post(`/api/event/${eventId}/register`);
}

eventsService.getRecommendedEvents = () => {
  return nodeInstance.get('/api/event/recommended');
}

eventsService.getSearchEvents = (word) => {
  const params = {
    word
  };
  return nodeInstance.get(`/api/event/search`, {params});
}

eventsService.getModerators = search => {
  return phpInstance.post(`/connection/searchUser/${search}`);
}

eventsService.getAllEvent = () => {
  const token = store.getters.getToken;
  const params = {
    token
  };
  return nodeInstance.get(`/api/event/events`, {params});
}

eventsService.getTodayEvent = () => {
  const token = store.getters.getToken;
  const params = {
    token
  };
  return nodeInstance.get(`/api/event/searchEventsDay`, {params});
}

eventsService.getAttendedEvent = () => {
  return nodeInstance.get(`/api/event/searchEventsAssisted`);
}

eventsService.getLastEvent = () => {
  return nodeInstance.get(`/api/event/searchEventsLast`);
}

eventsService.getInterestingEvent = () => {
  return nodeInstance.get(`/api/event/searchEventsInterest`);
}

eventsService.getMyEvents = () => {
  return nodeInstance.get(`/api/event/myEvents`);
}

eventsService.getEventCapacityList = () => {
  return nodeInstance.get(`/api/event/capacity`);
}

eventsService.getEventCapacityAvailability = ( initialHour, finalHour ) => {
  return nodeInstance.get(`/api/event/capacity/availability`,{params: { initialHour, finalHour } });
}

eventsService.getEvent = eventId => {
  return nodeInstance.get(`/api/event/${eventId}`);
}

export default eventsService;
