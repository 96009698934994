<template src="./DeleteEventModal.html">
</template>

<script>
  import eventsService from "@/services/Events";

  export default {
    name: 'delete-event-modal',
    props: {
      openDeleteEventModal: { type: Boolean, defaul: false },
      eventId: { type: String },
      code: { type: String }
    },
    methods: {
      eventDelete() {
        eventsService.deleteEvent(this.eventId, this.code)
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Se eliminó el evento con éxito",
              type: "is-success"
            });
            this.$emit('eventDeleted')
            this.closeModal()
          })
          .catch(error => {
            console.error(error)
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Falló la eliminación del evento.",
              type: "is-error"
            });
          })
          .finally(this.closeModal());
      },
      closeModal() {
        this.$emit('closeDeletEventModal');
      }
    }
  }
</script>

<style lang="scss" scoped src="./DeleteEventModal.scss">

</style>
